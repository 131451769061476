import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { FiChevronRight, FiHome } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectContractSortBy, selectContractSortDirection } from '../contract/contractSlice';
import { useEffect, useState } from 'react';

const BreadcrumbComponent = (props) => {
  const location = useLocation();
  const sortBy = useSelector(selectContractSortBy);
  const sortDir = useSelector(selectContractSortDirection);
  const { from } = location.state || {};
  const { state } = from || {};
  const [link, setLink] = useState('');
  const [linkName, setLinkName] = useState('');

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes('contracts')) {
        setLink(`/contracts?sortBy=${sortBy}&sortDirection=${sortDir}`);
        setLinkName('Contracts');
      } else if (location.pathname.includes('user_management')) {
        setLink(`/user_management?sortBy=${sortBy}&sortDirection=${sortDir}`);
        setLinkName('User Management');
      } else if (location.pathname.includes('troubleshooting')) {
        setLink('/troubleshooting');
        setLinkName('Troubleshooting');
      } else if (location.pathname.includes('certifications')) {
        setLink('/certifications');
        setLinkName('Certifications');
      }
    }
  }, [location.pathname, sortBy, sortDir]);

  return (
    <Breadcrumb fontSize="sm" spacing="8px" separator={<FiChevronRight />} color="gray.400" ml={{ base: 0, md: 64 }} p={4} {...props}>
      <BreadcrumbItem>
        <BreadcrumbLink to="/" as={RouterLink} aria-label="Home">
          <FiHome />
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink to={link} as={RouterLink} replace state={state}>
          {linkName}
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink as="span">{props.id || 'New'}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

BreadcrumbComponent.propTypes = {
  id: PropTypes.string,
};

export default BreadcrumbComponent;
