import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Layout from '../common/Layout';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '../common/select/styles';
import { useGetStreamsQuery, useLazyGetStreamGroupsQuery } from '@/app/services/nucleus';
import { prioritizeStreams, timeInterval } from './streamsHelper';
import { createColumnHelper } from '@tanstack/react-table';
import AlertComponent from '../common/AlertComponent';
import DataTable from '../common/table/DataTable';
import { isArrayValue } from '@/utils/helpers';
import SimpleTable from '../common/table/SimpleTable';

const ConsumerGroup = () => {
  const columnHelper = createColumnHelper();
  const labelColor = useColorModeValue('gray.400', 'gray.500');
  const [selectedInterval, setSelectedInterval] = useState(timeInterval[4]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [queueLength, setQueueLength] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isStreamInvalid, setIsStreamInvalid] = useState(false);
  const [polling, setPolling] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(30000);

  const { data: streams, isLoading, isError } = useGetStreamsQuery();
  const [getStream, { data: streamData, isError: isGetStreamError, error: getStreamError, isFetching }] = useLazyGetStreamGroupsQuery({
    pollingInterval,
  });

  const streamsColumn = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Group',
      }),
      columnHelper.accessor('consumers', {
        header: 'Consumers',
      }),
      columnHelper.accessor('last_delivered_id', {
        header: 'Last Delivered ID',
      }),
      columnHelper.accessor('last_delivered_at', {
        header: 'Last Delivered At',
      }),
      columnHelper.accessor('entries_read', {
        header: 'Entries Read',
      }),
      columnHelper.accessor('queue_length', {
        header: 'Queue Length',
      }),
    ],
    [],
  );

  const priorityStreams = ['stream:nucleus:inbound', 'stream:nucleus:outbound'];

  const prioritizedStreams = useMemo(() => prioritizeStreams(streams || [], priorityStreams), [streams, priorityStreams]);

  // Map prioritized streams to format for Select
  const options = useMemo(
    () =>
      prioritizedStreams.map((stream) => ({
        label: stream,
        value: stream,
      })),
    [prioritizedStreams],
  );

  useEffect(() => {
    if (polling) {
      stopPolling();
      startPolling();
    }
  }, [selectedInterval, selectedStream]);

  useEffect(() => {
    return () => stopPolling(); // Cleanup polling
  }, []);

  const handleQueueLengthChange = (e) => {
    const value = e.target.value;
    const intValue = value === '' ? null : Number(value);

    setQueueLength(value);

    if (value === '' || isNaN(intValue) || intValue < 0) {
      setIsInvalid(true);
      setQueueLength('');
    } else {
      setIsInvalid(false);
      setQueueLength(intValue);
    }
  };

  const getRowColor = (queueLength, queueLengthThreshold) => {
    if (queueLength === 0) {
      return 'green.100'; // Green for queue length 0 always
    }

    if (queueLength < queueLengthThreshold) {
      return 'green.100'; // Green for queue length < threshold
    }

    if (queueLength >= queueLengthThreshold) {
      return 'red.100'; // Red for queue length >= threshold
    }
    return 'white'; // Default to whites
  };

  const onTableLoaded = (table) => {
    if (isArrayValue(streamData)) {
      streamData.forEach((stream, idx) => {
        const color = getRowColor(stream?.queue_length, queueLength);
        table.options.meta?.addRowColor(idx, color);
      });
    }
  };

  const fetchStreamData = () => {
    getStream({ stream_name: selectedStream?.value })
      .unwrap()
      .then((response) => {
        console.log('Successfully fetched streams:', response);
      })
      .catch((error) => {
        console.log('Error fetching stream data:', error);
      });
  };

  // Manually fetches data when stream is selected
  const startPolling = () => {
    if (!selectedStream) {
      setIsStreamInvalid(true);
      return;
    }
    setIsStreamInvalid(false);
    setPolling(true);
    setPollingInterval(selectedInterval.value * 1000);
    fetchStreamData();
  };

  const stopPolling = () => {
    setPolling(false);
    setPollingInterval(0);
  };

  const handleStartPolling = (e) => {
    e.preventDefault();
    if (!polling) startPolling();
  };

  const handleStopPolling = (e) => {
    e.preventDefault();
    stopPolling();
  };

  return (
    <Box data-testid="pact-consumer-group">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Consumer Groups
        </Heading>
        <Box mt="6" p={0}>
          <HStack align={'start'} spacing={4}>
            <FormControl id="consumer-group" maxW={'50%'} isInvalid={isStreamInvalid}>
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                <Text color="red.500">Failed to load streams</Text>
              ) : (
                <>
                  <FormLabel>Streams</FormLabel>
                  <Select
                    aria-label="streams-select"
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    placeholder="Select a stream"
                    options={options}
                    value={selectedStream}
                    onChange={(value) => setSelectedStream(value)}
                    isSearchable
                  />
                  {isStreamInvalid && <FormErrorMessage>You must select a stream.</FormErrorMessage>}
                </>
              )}
            </FormControl>

            <FormControl id="polling-time" maxW={'50%'} aria-label={'polling-time-control'}>
              <FormLabel>Polling Time</FormLabel>
              <Select
                aria-label="polling-time-select"
                chakraStyles={chakraStyles}
                focusBorderColor="brand.700"
                placeholder="Select refresh frequency"
                options={timeInterval}
                value={selectedInterval}
                onChange={(value) => setSelectedInterval(value)}
                isSearchable
              />
              {isInvalid && <FormErrorMessage>Limit must be greater than 0.</FormErrorMessage>}
            </FormControl>

            <FormControl id="queue-length" maxW={'50%'} aria-label={`queue-length-control`}>
              <FormLabel>Queue Length Threshold</FormLabel>
              <Input
                id="queue-length-input"
                aria-label="queue-length-input"
                type="text"
                min="0"
                value={queueLength}
                onChange={handleQueueLengthChange}
                placeholder="Alert Threshold"
                _placeholder={{ color: labelColor }}
              />
            </FormControl>

            <Box mt={8}>
              <Button
                variant={polling ? 'solid' : 'purple'}
                colorScheme={polling ? 'red' : ''}
                onClick={polling ? handleStopPolling : handleStartPolling}
              >
                {polling ? 'Stop' : 'Start'}
              </Button>
            </Box>
          </HStack>
        </Box>

        {isGetStreamError ? (
          <AlertComponent status="warning" title="Error fetching Stream Data" description={getStreamError?.data?.detail} />
        ) : (
          <Box mt={8}>
            <SimpleTable noResults={streamData?.length === 0} columns={streamsColumn} data={streamData} onLoaded={onTableLoaded} />
          </Box>
        )}
      </Layout>
    </Box>
  );
};

export default ConsumerGroup;
