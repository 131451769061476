import SSDataTable from '@/features/common/table/SSDataTable';
import TruncatedTextComponent from '@/features/common/TruncatedTextComponent';
import { useEffect, useState, useMemo } from 'react';
import { useLazySearchTracersQuery } from '@/app/services/nucleus';
import SearchInput from '@/features/common/SearchInput';
import AlertComponent from '../common/AlertComponent';
import { Box, Heading, Switch, HStack, FormControl, FormLabel, Center } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { usePagination } from '@/hooks/usePagination';
import { Link } from 'react-router-dom';

const SearchTracer = () => {
  const [errorDescription, setErrorDescription] = useState('');
  const [tracerData, setTracerData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isOnlyErrors, setIsOnlyErrors] = useState(false);
  const [pageCount, setPageCount] = useState();
  const { limit, onPaginationChange, pagination: paginationState, offset } = usePagination();
  const [getTracers, { isLoading, isError }] = useLazySearchTracersQuery();
  const [sortingState, setSortingState] = useState([{ 'id': 'updated_at', 'desc': true }]);

  const onSortingChange = (sorting) => {
    setSortingState(sorting);
  };

  const columnHelper = createColumnHelper();

  const tracerColumns = useMemo(
    () => [
      columnHelper.accessor('summary', {
        header: 'Summary',
        cell: (info) => {
          return <TruncatedTextComponent key={`${info.getValue()}`} title="Summary" text={info.getValue()} length={100} />;
        },
        size: 65,
        meta: { widthStrategy: '%' },
        enableSorting: false,
      }),
      columnHelper.accessor('error_count', {
        header: 'Number of Errors',
        enableSorting: false,
        size: 15,
        cell: (info) => <Center paddingRight={4}>{info.getValue()}</Center>,
        meta: { align: 'center', widthStrategy: '%' },
      }),
      columnHelper.accessor('inserted_at', {
        header: 'Inserted At',
        cell: (info) => {
          const date = new Date(info.getValue());
          return date.toLocaleString();
        },
        size: 10,
        meta: { widthStrategy: '%' },
      }),
      columnHelper.accessor('updated_at', {
        header: 'Updated At',
        cell: (info) => {
          const date = new Date(info.getValue());
          return date.toLocaleString();
        },
        size: 10,
        meta: { widthStrategy: '%' },
      }),
      columnHelper.accessor('', {
        header: 'Trace',
        cell: (info) => {
          const tracer = info.row.original;
          return (
            <HStack fontWeight="bold">
              <Link to={`tracer/${tracer?.trace_id}`} style={{ color: '#514689' }}>
                Trace
              </Link>
            </HStack>
          );
        },
        size: 10,
        meta: { widthStrategy: '%' },
      }),
    ],
    [],
  );

  const getSortByField = () => {
    if (sortingState.length !== 0) {
      const prefix = sortingState[0].desc ? '-' : '';
      return `${prefix}${sortingState[0].id}`;
    }
    return undefined;
  };

  const searchTracers = () => {
    const errorCount = isOnlyErrors ? 1 : 0; // 1 for errors, 0 for all
    const sortBy = getSortByField();

    getTracers({ search: searchText, errorCount, sortBy, limit, offset })
      .unwrap()
      .then((response) => {
        setTracerData(response.items);
        setPageCount(Math.ceil(response?.total / limit));
      })
      .catch((error) => {
        console.log('error', error);
        setErrorDescription('Error fetching tracers');
        setTracerData([]);
      });
  };

  const handleClearSearchText = () => {
    setSearchText('');
  };

  const handleSearchTextChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    searchTracers();
  }, [isOnlyErrors, sortingState, limit, offset]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      searchTracers();
    }, 250);

    return () => clearTimeout(debounceTimeout);
  }, [searchText]);

  const getRowProps = (row) => {
    const color = row.original.error_count > 0 ? 'red.50' : 'white';
    return { bgColor: color };
  };

  return (
    <Box data-test-id="pact-search-tracer">
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Search Tracers
      </Heading>
      <Box p={5} width={{ base: '100%' }}>
        <HStack mt={3} mb={1} spacing={8}>
          <SearchInput isDisabled={false} searchText={searchText} dataType="text" onChange={handleSearchTextChange} onClear={handleClearSearchText} />
          <FormControl id="sortBy" display="flex" alignItems="center">
            <FormLabel htmlFor="errorsOnly" mb="0" ml={2}>
              Errors Only
            </FormLabel>
            <Switch id="errorsOnly" name="errorsOnly" size="md" colorScheme="cyan" onChange={(e) => setIsOnlyErrors(e.target.checked)} />
          </FormControl>
        </HStack>
      </Box>
      {isError ? (
        <AlertComponent status="warning" title="Search Tracers Error" description={errorDescription} />
      ) : (
        <SSDataTable
          loading={isLoading}
          data={tracerData}
          columns={tracerColumns}
          pagination={paginationState}
          pageCount={pageCount}
          onPaginationChange={onPaginationChange}
          sorting={sortingState}
          onSortingChange={onSortingChange}
          getRowProps={getRowProps}
        />
      )}
    </Box>
  );
};

export default SearchTracer;
