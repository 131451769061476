import { Box, Button, Heading, HStack, Link, List, ListItem, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrl, useGetTracerQuery } from '@/app/services/nucleus';
import { createColumnHelper } from '@tanstack/react-table';
import AlertComponent from '../common/AlertComponent';
import DataTable from '../common/table/DataTable';
import { LiaLongArrowAltRightSolid } from 'react-icons/lia';
import { LuArrowRightToLine, LuArrowRightFromLine } from 'react-icons/lu';
import TruncatedTextComponent from '../common/TruncatedTextComponent';
import { isArrayValue } from '@/utils/helpers';

const ViewTracer = () => {
  const { id } = useParams();
  const columnHelper = createColumnHelper();

  const { data: tracerData, isLoading, isError, error: tracerError } = useGetTracerQuery({ trace_id: id });

  const createDateColumn = (columnHelper, id, header) => {
    return columnHelper.accessor(id, {
      header: header,
      cell: (info) => {
        const date = new Date(info.getValue());
        return date.toLocaleString();
      },
      size: 10,
      meta: { widthStrategy: '%' },
    });
  };

  const tracerDataColumn = useMemo(
    () => [
      columnHelper.accessor('error_count', {
        header: 'Number of Errors',
        enableSorting: false,
        size: 15,
        cell: (info) => info.getValue(),
        meta: { widthStrategy: '%' },
      }),
      createDateColumn(columnHelper, 'inserted_at', 'Inserted At'),
      createDateColumn(columnHelper, 'updated_at', 'Updated At'),
      columnHelper.accessor('', {
        header: 'Action',
        cell: ({ row }) => {
          return (
            <Link aria-label="download file" href={`${baseUrl}v1/streams/tracers/${row.original.trace_id}/export`} isExternal>
              <Button size="sm" variant="outline">
                Export Tracer
              </Button>
            </Link>
          );
        },
        size: 10,
        meta: { widthStrategy: '%' },
      }),
    ],
    [],
  );

  const getRowColor = (errorCount) => {
    return errorCount > 0 ? 'red.100' : 'white';
  };

  const onTableLoaded = (table) => {
    if (isArrayValue(tracerData)) {
      tracerData.forEach((trace, idx) => {
        const color = getRowColor(trace.error_count);
        table.options.meta?.addRowColor(idx, color);
      });
    }
  };

  return (
    <Box data-testid="pact-tracer-logs">
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        View Tracer
      </Heading>

      {isError ? (
        <AlertComponent status="error" title="Error fetching tracer data" description={tracerError?.data?.detail} />
      ) : (
        <Box p={5} width={{ base: '100%' }}>
          <DataTable
            showScrollbar={false}
            columns={tracerDataColumn}
            data={tracerData}
            loading={tracerData?.length > 1}
            paginate={false}
            onLoaded={onTableLoaded}
            loadingDependencies={[isLoading]}
          />
        </Box>
      )}

      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Logs
      </Heading>

      <List spacing={2} mt={4}>
        {isArrayValue(tracerData) &&
          tracerData[0].logs.map((log, idx) => (
            <ListItem
              key={`${idx}`}
              border="1px"
              borderColor="gray.200"
              p={4}
              borderRadius="md"
              bg={log.error_message ? 'red.100' : log.handled ? 'green.100' : 'white'}
            >
              <VStack align="start" spacing={2}>
                <HStack justify="space-between" width="100%">
                  <HStack>
                    {log.search_field ? (
                      <LuArrowRightToLine size={20} />
                    ) : log.consumer_group && log.consumer_name ? (
                      <LuArrowRightFromLine size={20} />
                    ) : null}
                    <Text fontWeight="bold">{log.stream_name}</Text>
                  </HStack>
                  <Text fontSize="sm">{log.message_id}</Text>
                </HStack>
                <HStack justify="space-between" width="100%" alignItems={'start'}>
                  <Box fontSize="sm" color="gray.500" width="50%">
                    {log.search_field ? (
                      <TruncatedTextComponent key={log.search_field} title="Search Field" text={log.search_field} length={80} />
                    ) : (
                      <Text>
                        {log.consumer_group} - {log.consumer_name}
                      </Text>
                    )}
                  </Box>

                  <HStack fontSize="sm" color="gray.500">
                    <Text>Started {new Date(log.started).toLocaleString()}</Text>
                    <LiaLongArrowAltRightSolid />
                    <Text>Completed {new Date(log.completed_at).toLocaleString()}</Text>
                  </HStack>
                </HStack>
              </VStack>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default ViewTracer;
