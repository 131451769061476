import React, { useState, useMemo, useEffect } from 'react';
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Box,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';
import PropTypes from 'prop-types';

const cleanString = (string) => {
  const noSpecials = string.replace(/[^a-zA-Z ]/g, '');
  return noSpecials.toLowerCase().replace(/ /g, '_');
};
/**
 * Truncates strings at word boundary
 * all internal state is memoized to prevent screen updates from causing visible artifacts
 * @param {string} title - The title appears in the Popover
 * @param {string} text - The string that will be truncated.
 * @param {number} length - The max length of the string.
 * @returns {Element}
 */
const TruncatedTextComponent = ({ title, text, length }) => {
  const [marker, setMarker] = useState(0);
  const [showPop, setShowPop] = useState(false);

  // create a lowercase all alphabetic string for HTML attributes
  const cleanTitle = useMemo(() => {
    return cleanString(title);
  }, [title]);

  const dataList = useMemo(() => {
    const splitList = text && text?.split('\n');
    // create obj with id for React key
    return splitList
      ? splitList.map((item) => {
          const id = cleanString(item).slice(0, item.length / 2);
          return {
            id,
            value: item,
          };
        })
      : [];
  }, [text]);

  const truncatedString = useMemo(() => {
    let tmpString = '';
    if (text) {
      const splitList = text.split('\n');
      splitList.forEach((fac, i) => {
        if (marker === 0) {
          tmpString = tmpString === '' ? fac : tmpString + `, ${fac}`;
        }
        if (tmpString.length >= length && marker === 0) {
          const re = new RegExp(`.{${length}}\\w*|.*`, 'g');
          const match = re.exec(tmpString);
          tmpString = match[0];
          setMarker(i + 1);
        }
      });
    }
    return tmpString;
  }, [text]);

  useEffect(() => {
    if (dataList && dataList.length > 1 && [...truncatedString].length < dataList.length) {
      setShowPop(true);
    } else if (truncatedString.length < dataList[0].value.length) {
      setShowPop(true);
    } else {
      setShowPop(false);
    }
  }, [truncatedString, dataList]);

  if (showPop) {
    return (
      <span>
        <span aria-label={`truncated ${cleanTitle}`}>{truncatedString + ` `}</span>
        <Popover placement="bottom">
          <PopoverTrigger>
            <IconButton variant="ghost" colorScheme={'cyan'} aria-label={`view ${cleanTitle}`} icon={<FiMoreHorizontal />} />
          </PopoverTrigger>
          <PopoverContent minW={{ base: '100%', lg: '800px' }} aria-label="popover content">
            <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box
                overflowY="scroll"
                maxH={500}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#cccccc',
                    borderRadius: '24px',
                  },
                }}
              >
                {dataList.map((facility, i) => {
                  return <Text key={`${facility.id}-${i}`}>{facility.value}</Text>;
                })}
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </span>
    );
  } else {
    return <span aria-label={cleanTitle}>{truncatedString}</span>;
  }
};

TruncatedTextComponent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  length: PropTypes.number,
};

export default TruncatedTextComponent;
