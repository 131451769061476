import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { scrollbar } from '@/utils/constants';
import { addColorData, updateTableData } from '@/features/common/table/helper';

const SimpleTable = ({ noResults, data, columns, onLoaded, onLoadedDependencies = [] }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      setTableData(() => data);
    }
  }, [data]);

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      addRowColor: (rowIndex, value) => {
        setTableData((old) => addColorData(rowIndex, old, value));
      },
      updateData: (rowIndex, columnId, value) => {
        setTableData((old) => updateTableData(rowIndex, columnId, old, value));
      },
    },
  });

  useEffect(() => {
    if (table && data && onLoaded) {
      onLoaded(table);
    }
  }, [table, data, ...onLoadedDependencies]);

  return (
    <TableContainer css={scrollbar}>
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {noResults ? (
            <Tr>
              <Td colSpan={columns.length} textAlign={'center'}>
                No Results
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id} bgColor={row?.original?.color ? row.original.color : 'white'}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

SimpleTable.propTypes = {
  noResults: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  onLoaded: PropTypes.func,
  onLoadedDependencies: PropTypes.array,
};

export default SimpleTable;
